import React from 'react';
import styled from "styled-components";
import logo from '../../asset/image/Jaewoologo.jpg'
import {detailMenu} from "../../dataSet";

const Header = () => {
	const headerList = [
		{title:'회사소개', link:'/greeting'},
		{title:'제품소개', link:'/mold'},
		{title:'설비현황', link:'/factory1'},
		{title:'Smart Factory', link:'/smart'},
		{title:'고객지원', link:'/consulting'},
	]



	const [openMenu, setOpenMenu] = React.useState(false)

	return (
		<BaseComponent>
			<div style={{display:'flex',height:'80px',justifyContent:'center'}}>
				<div style={{display:'flex'}}>
					<img src={logo} alt="logo" style={{padding:'8px', boxSizing:'border-box',width:'125px', cursor:'pointer'}} onClick={()=>window.location.href = '/main'}/>
					<div style={{color:'#223763',fontStyle:'italic',fontSize:'22px', fontWeight:900, alignSelf:'center',width:'240px'}}>{'JAEWOO INDUSTRY'}</div>
				</div>
				<div style={{display:'flex', cursor:'pointer'}} onMouseEnter={()=>setOpenMenu(true)}>
					{headerList.map((v)=>(
						<div className={'header-title'} onClick={()=>window.location.href = v.link} key={v.title}>{v.title}</div>
					))}
				</div>
			</div>
			<div style={{height:`${openMenu? 250 : 0}px`,
				justifyContent:'center',
				backgroundColor:'rgba(255,255,255,0.7)',
				transition: 'all 0.2s', display:'flex',overflow:'hidden'}} onMouseLeave={()=>setOpenMenu(false)}>
				<SpreadMenu>
					<div>
						{detailMenu.company.map((v)=>(<div key={v.title} onClick={()=>window.location.href = `${v.link}`}>{v.title}</div>))}
					</div>
					<div>
						{detailMenu.product.map((v)=>(<div key={v.title} onClick={()=>window.location.href = `${v.link}`}>{v.title}</div>))}
					</div>
					<div>
						{detailMenu.facility.map((v)=>(<div key={v.title} onClick={()=>window.location.href = `${v.link}`}>{v.title}</div>))}
					</div>
					<div>
						{detailMenu.smart.map((v)=>(<div key={v.title} onClick={()=>window.location.href = `${v.link}`}>{v.title}</div>))}
					</div>
					<div>
						{detailMenu.customer.map((v)=>(<div key={v.title} onClick={()=>window.location.href = `${v.link}`}>{v.title}</div>))}
					</div>
				</SpreadMenu>
			</div>
		</BaseComponent>
	);
};

export default Header;

const BaseComponent = styled.div`
  height: 80px;
  position: sticky;
  background-color: #fff;
  top: 0;
  left: 0;
  user-select: none;
  z-index: 100;
  box-shadow: 0 1px 5px #dadada;
	
	.header-title{
		text-align: center;
		align-self: center;
		width: 160px;
		&:hover{
			color: #233763;
			font-weight: 700;
		}
	}
	
`

const SpreadMenu = styled.div`
	display: flex;
	text-align: center;
	padding-left: 365px;
	position: relative;
	&>div{
		width: 160px;
		&>div{
			box-sizing: border-box;
			line-height: 48px;
			align-self: center;
			cursor: pointer;
			position: relative;
			user-select: none;
			&::before{
				content:'';
				position: absolute;
				bottom: 0;
				right:10%;
				width:80%;
				height: 0;
				background-color: #223763;
				box-sizing: border-box;
			}
			
			&:hover{
				color: #223763;
				font-weight: 700;
				&::before{
          height: 4px;
				}
			}
		}
	}
`