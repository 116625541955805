import React from 'react';
import {ContainerFrame, ContentFrame, PageTitle} from "../../style/Style-component";
import MagnificationImage from "../../component/box/MagnificationImage";
import label from "../../asset/image/sample/lasercut.jpg";
import SideMenu from "../../component/sidemenu/SideMenu";
import {detailMenu, facility_data} from "../../dataSet";
import FacilityInfoBox from "../../component/box/FacilityInfoBox";

const FirstFactory = () => {
  const [selectImage, setSelectImage] = React.useState<{img: string, name: string, width:number}>({img:'', name:'',width:0})
  return (
    <React.Fragment>
      <ContainerFrame>
        {selectImage.img && <MagnificationImage imgUrl={selectImage.img} facilityName={selectImage.name} verticalPicture={selectImage.width === 300}
                                                closeImg={()=>setSelectImage({img:'', name:'',width:0})}/>}
        <div className={'top-label'} style={{backgroundImage:`url(${label})`}}/>
        <div style={{display:'flex',justifyContent:'center'}}>
          <SideMenu menuTitle={'설비 현황'} menuList={detailMenu.facility}/>
          <ContentFrame>
            <PageTitle>{'설비 현황'}</PageTitle>
            <div style={{fontFamily:'S-CORE', fontSize:'22px', fontWeight:800, color:'#233763',margin:'8px 0'}}>{'◈ 제 1 공장'}</div>
            <div style={{display:'grid', gridTemplateColumns:'repeat(3, 1fr)', gap:'30px'}}>
              {facility_data.factory1.map((v)=>(
                <FacilityInfoBox facilityName={v.name} imgUrl={v.img} width={v.width} selectEvent={()=>setSelectImage(v)}/>
              ))}
            </div>
          </ContentFrame>
        </div>
      </ContainerFrame>
    </React.Fragment>
  );
};

export default FirstFactory;