import styled from "styled-components";


export const ContentFrame = styled.div`
	width: 900px;
	box-sizing: border-box;
	padding: 36px;
`
export const ContainerFrame = styled.div<{labelPosition?:string, height?:number}>`
	min-height: ${props => props.height ?? 650}px;
		box-sizing: border-box;
	.top-label{
	height: 200px;
	background-color: #61dafb;
	background-repeat: no-repeat;
	background-position: ${props => props.labelPosition ?? 'center'};
	background-size: cover;
`

export const PageTitle = styled.div`
	font-size: 28px;
	font-weight: 800;
	font-family: S-Core;
`