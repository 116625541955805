import React from 'react';
import styled from "styled-components";

interface Magnification_Props{
  imgUrl?: string
  closeImg?:()=>void
  facilityName?: string
  verticalPicture?: boolean
}
const MagnificationImage:React.FunctionComponent<Magnification_Props> = ({imgUrl, closeImg,facilityName, verticalPicture}) => {
  return (
    <BaseComponent onClick={closeImg}>
      <div style={{border:'15px solid #d2d2d2', borderRadius:'12px',boxShadow:'0 3px 5px gray'}}>
        <div className={'mag-image'} style={{backgroundImage:`url(${imgUrl})`, width: verticalPicture?'450px' : '600px', height: verticalPicture?'600px' : '450px', cursor:'pointer',backgroundColor:'#fff'}}/>
        <div style={{height:'40px', backgroundColor:'#233763',lineHeight:'40px',textAlign:'center',fontSize:'18px',color:'#fff'}}>{facilityName ?? '설비명'}</div>
      </div>
    </BaseComponent>
  );
};

export default MagnificationImage;

const BaseComponent = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: rgba(40, 44, 52, 0.5);
  justify-content: center;
  align-items: center;

  .mag-image{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`