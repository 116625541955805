import React from 'react';
import {ContainerFrame, ContentFrame, PageTitle} from "../../style/Style-component";
import label from "../../asset/image/sample/bolt.jpg";
import SideMenu from "../../component/sidemenu/SideMenu";
import {detailMenu} from "../../dataSet";
import history from '../../asset/image/history.png'

const History = () => {
  return (
    <ContainerFrame labelPosition={'top'}>
      <div className={'top-label'} style={{backgroundImage:`url(${label})`}}/>
      <div style={{display:'flex',justifyContent:'center'}}>
        <SideMenu menuTitle={'회사 소개'} menuList={detailMenu.company}/>
        <ContentFrame>
          <PageTitle>{'회사연혁'}</PageTitle>
          <div style={{display:'flex'}}>
            <img src={history} alt="history" style={{marginTop:'16px',border:'1px solid #233763'}}/>
          </div>
        </ContentFrame>
      </div>
    </ContainerFrame>
  );
};

export default History;