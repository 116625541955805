import fac1_hanoul_1 from '../src/asset/image/factory_1/fac1_hanoul_1.jpg'
import fac1_hanoul_2 from '../src/asset/image/factory_1/fac1_hanoul_2.jpg'
import fac1_jhnpress_1 from '../src/asset/image/factory_1/fac1_jhnpress_1.jpg'
import fac1_kukil_1 from '../src/asset/image/factory_1/fac1_kukil_1.jpg'
import fac1_simpac_1 from '../src/asset/image/factory_1/fac1_simpac_1.jpg'
import fac1_ssangyong_1 from '../src/asset/image/factory_1/fac1_ssangyong_1.jpg'
import fac1_ssangyong_2 from '../src/asset/image/factory_1/fac1_ssangyong_2.jpg'

import fac2_hanoul_1 from '../src/asset/image/factory_2/fac2_hanoul_1.jpg'
import fac2_hncp_60 from '../src/asset/image/factory_2/fac2_hncp-60.jpg'
import fac2_kukil_1 from '../src/asset/image/factory_2/fac2_kukil_1.jpg'
import fac2_lift from '../src/asset/image/factory_2/fac2_lift.jpg'
import fac2_NC_feeder_1 from '../src/asset/image/factory_2/fac2_NC_feeder_1.jpg'
import fac2_NC_feeder_2 from '../src/asset/image/factory_2/fac2_NC_feeder_2.jpg'
import fac2_seoul_1 from '../src/asset/image/factory_2/fac2_seoul_1.jpg'
import fac2_seoul_2 from '../src/asset/image/factory_2/fac2_seoul_2.jpg'
import fac2_seoul_3 from '../src/asset/image/factory_2/fac2_seoul_3.jpg'

import bending_machine from '../src/asset/image/etc/bending_machine.jpg'
import shearing_machine from '../src/asset/image/etc/shearing_machine.jpg'
import mold_1 from '../src/asset/image/etc/mold_1.jpg'
import mold_2 from '../src/asset/image/etc/mold_2.jpg'
import mold_3 from '../src/asset/image/etc/mold_3.jpg'
import mold_4 from '../src/asset/image/etc/mold_4.jpg'




export const detailMenu = {
	company: [
		{title:'CEO 인사말', link:'/greeting'},
		{title:'협력업체', link:'/partner'},
		{title:'회사연혁', link:'/history'},
		{title:'인증현황', link:'/certificate'},
		{title:'찾아오시는 길', link:'/location'}
	],
	product: [
		{title:'금형설계 및 제작', link:'/mold'},
		{title:'프레스 가공', link:'/press'},
		{title:'Locking-Nut', link:'/locking'}
	],
	facility: [
		{title:'제 1공장', link:'/factory1'},
		{title:'제 2공장', link:'/factory2'},
		{title:'금형실', link:'/moldroom'}
	],
	smart: [
		{title:'', link:''}
	],
	customer:[
		{title:'온라인 상담', link:'/consulting'},
		{title:'공지사항', link:'/notice'}
	]
}


export const ceo_greeting = `당사는 금형 및 산업기계 부품, 송전용 금구 류 풀림 방지 너트(LOCKING-NUT)를 전문적으로 제조하고 생산하는 업체로서 최고의 품질을 제공하고자 
쉼 없이 노력하여 왔습니다.

	정직, 화합, 열정을 사훈으로 하여 완성도 높은 제품 생산을 통해 고객의 니즈를 
	충족하려 노력하고 있습니다. 
	
	또한 2013년에 ISO9001, ISO14001인증을 취득해 품질경영, 
	환경경영에 힘쓰고 있으며 전문적인 기술진을 보유하고 있어 
	품질에 대해 확신을 갖고 있습니다.
	
	꾸준한 투자와 끊임없는 개발로 제품경쟁력을 높이며, Smart-Factory사업을 통해 오늘보다 더 나은 혁신적인 미래를 만들어가고자 합니다.
	
	성장하는 기업, 진취적이고 책임있는 기업으로 나아가도록 최선을 다하겠습니다. 많은 관심과 격려 부탁 드립니다.
	
	감사합니다.`


export const facility_data = {
	factory1:[
		{img: fac1_hanoul_1, name: '설비명', width: 300},
		{img: fac1_hanoul_2, name: '설비명', width: 300},
		{img: fac1_jhnpress_1, name: '설비명', width: 300},
		{img: fac1_kukil_1, name: '설비명', width: 300},
		{img: fac1_simpac_1, name: '설비명', width: 300},
		{img: fac1_ssangyong_1, name: '설비명', width: 300},
		{img: fac1_ssangyong_2, name: '설비명', width: 300},
	],
	factory2:[
		{img: fac2_hanoul_1, name: '설비명', width: 300},
		{img: fac2_hncp_60, name: '설비명', width: 300},
		{img: fac2_NC_feeder_1, name: '설비명', width: 400},
		{img: fac2_kukil_1, name: '설비명', width: 300},
		{img: fac2_seoul_1, name: '설비명', width: 300},
		{img: fac2_NC_feeder_2, name: '설비명', width: 400},
		{img: fac2_seoul_2, name: '설비명', width: 300},
		{img: fac2_seoul_3, name: '설비명', width: 300},
		{img: fac2_lift, name: '설비명', width: 400},
	]
	,
	mold:[
		{img: bending_machine, name: '설비명', width: 300},
		{img: mold_1, name: '설비명', width: 300},
		{img: shearing_machine, name: '설비명', width: 400},
		{img: mold_3, name: '설비명', width: 300},
		{img: mold_4, name: '설비명', width: 300},
		{img: mold_2, name: '설비명', width: 400},
	]
}