import React from 'react';
import styled from "styled-components";
import sideback from '../../asset/image/sample/sideback.jpg'
import {useLocation} from "react-router-dom";

interface Side_Props{
	menuTitle: string
	menuList:{title:string, link:string}[]
}
const SideMenu:React.FunctionComponent<Side_Props> = ({menuTitle, menuList}) => {

	const location = useLocation()

	return (
		<BaseComponent>
			<div style={{backgroundImage:`url(${sideback})`}}>{menuTitle}</div>
			{menuList.map((v)=>(
				<DetailMenu className={'detail-menu'} selected={location.pathname === v.link} onClick={()=>window.location.href = v.link}>{v.title}</DetailMenu>
			))}
		</BaseComponent>
	);
};

export default SideMenu;

const BaseComponent = styled.div`
  width: 250px;
	
  & > div:first-child {
    font-size: 32px;
    text-shadow: black 1px 3px 5px;
    color: #fff;
    font-weight: 700;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const DetailMenu = styled.div<{selected?:boolean}>`
	height: 60px;
	margin: 2px 0;
	box-sizing: border-box;
	padding-left: 16px;
	display: flex;
	align-items: center;
	background-color: ${props => props.selected? '#233763' : '#d0e3f6'};
	color: ${props => props.selected? '#fff' : '#737679'};
	text-indent: ${props => props.selected? '16px' : 0};
	user-select: none;
	cursor: pointer;
	transition: all 0.1s;
	font-family: S-Core;

	&:hover {
		color: #fff;
		background-color: #233763;
		text-indent: 16px;
	}
`