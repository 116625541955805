import React from 'react';
import logo from '../../asset/image/Jaewoologo.jpg'
import outFactory from '../../asset/image/out_state/out_factory_4.jpg'
import sideImg from '../../asset/image/sample/worker.jpg'
import nut from '../../asset/image/sample/nut.png'
import press from '../../asset/image/sample/press.png'
import design from '../../asset/image/sample/design.png'
import styled from "styled-components";
import {ContainerFrame} from "../../style/Style-component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../../component/footer/Footer";

const MainTopContainer = () => {

	const setting = {
		infinite: true,
		speed: 500,
		slidesToScroll: 1,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		fade:true,
		arrows: false
	}

	const productNav = [
		{icon: design, title: '금형 설계 및 제작', text:'DIEMOLD', link:'/mold'},
		{icon: press, title: '프레스 가공', text:'PRESS', link:'/press'},
		{icon: nut, title: '풀림방지 너트', text:'LOCKING-NUT',link:'/locking'}
	]


	return (
		<ContainerFrame>
			<div style={{display:'grid',gridTemplateColumns:'620px 1300px',justifyContent:'center',height:'550px'}}>
				<div style={{height:'550px', backgroundColor:'#fff',fontFamily:'S-CORE',
					alignItems:'center', display:'flex', flexDirection:'column', justifyContent:'center'}}>
					<img src={logo} alt="logo" width={500}/>
					<div style={{marginTop:'30px'}}>
						<div>{'재우산업에 방문하신 것을 환영합니다.'}</div>
						<div style={{color:'#233763', fontSize:'40px', fontWeight:800 }}>{'JAEWOO INDUSTRY'}</div>
					</div>
				</div>
				<Slider {...setting}>
					<div>
						<div style={{backgroundImage:`url(${outFactory})`,
							backgroundRepeat:'no-repeat', backgroundPosition:'center', backgroundSize:'cover', height:'550px', width:'100%',backgroundColor:'#223763'}}></div>
					</div>
					<div>
						<div style={{backgroundImage:`url(${outFactory})`,
							backgroundRepeat:'no-repeat', backgroundPosition:'center', backgroundSize:'cover', height:'550px', width:'100%',backgroundColor:'#223763'}}></div>
					</div>
				</Slider>
			</div>

			<div style={{display:'grid', gridTemplateColumns:'620px 1300px', justifyContent:'center', height:'200px'}}>
				<div style={{backgroundImage:`url(${sideImg})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundPosition:'center'}}/>
				<div style={{backgroundColor:'#233763',display:'grid', gridTemplateColumns:'repeat(3, 1fr)'}}>
					{productNav.map((v)=>(
						<NavSelect onClick={()=>window.location.href = v.link}>
							<img src={v.icon} alt="" width={50} height={50}/>
							<div style={{color:'#fff',marginLeft:'24px'}}>
								<div style={{fontSize:'30px'}}>{v.title}</div>
								{/*<div style={{fontSize:'20px'}}>{v.text}</div>*/}
							</div>
						</NavSelect>
					))}
				</div>
			</div>
			<Footer shortType={true}/>

		</ContainerFrame>
	);
};

export default MainTopContainer;

const NavSelect= styled.div`
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding-left: 60px;
  background: linear-gradient(120deg, #223763, #09204e);
	user-select: none;
	cursor: pointer;
`