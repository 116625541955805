import React from 'react';
import styled from "styled-components";

interface Footer_Types{
	shortType?:boolean
}
const Footer:React.FunctionComponent<Footer_Types> = ({shortType}) => {

	return (
		<BaseComponent style={{height: `${shortType? 100 : 200}px`}}>
			{shortType?
				<React.Fragment>
					<div>{'Copyright ⓒ 재우산업 All rights reserved'}</div>
					<div>{'재우산업 | 사업자등록번호: 128-23-08842 | 대표자명: 송윤길 | 주소: 경기 김포시 대곶면 율마로 181번길 31-34 | TEL:(031)987-2718 | FAX:(031)987-2718 | E-MAIL: jae3638@nate.com'}</div>
				</React.Fragment>

				:

				<React.Fragment>
					<div>{'Copyright ⓒ 재우산업 All rights reserved'}</div>
					<div>{'재우산업 | 사업자등록번호: 128-23-08842 | 대표자명: 송윤길'}</div>
					<div>{'경기 김포시 대곶면 율마로 181번길 31-34'}</div>
					<div>{'TEL:(031)987-2718 | FAX:(031)987-2718 | E-MAIL: jae3638@nate.com'}</div>
				</React.Fragment>
			}
		</BaseComponent>
	);
};

export default Footer;

const BaseComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eef1f6;

  & > div:first-child {
    font-size: 15px;
    font-weight: 700;
    margin: 12px 0;
    color: #42424d;
  }

  & > div:nth-child(n+2) {
    font-size: 13px;
    margin: 2px 0;
    color: #737679;
  }
`