import React from 'react';
import {ContainerFrame, ContentFrame, PageTitle} from "../style/Style-component";
import label from "../asset/image/sample/bolt.jpg";
import SideMenu from "../component/sidemenu/SideMenu";

interface Empty_Props {
	title: string
	sideTitle: string
	sideDetailMenu:{title:string, link:string}[]
}
const Empty:React.FC<Empty_Props> = ({title, sideTitle, sideDetailMenu}) => {
	return (
		<ContainerFrame labelPosition={'top'}>
			<div className={'top-label'} style={{backgroundImage:`url(${label})`}}/>
			<div style={{display:'flex',justifyContent:'center'}}>
				<SideMenu menuTitle={sideTitle} menuList={sideDetailMenu}/>
				<ContentFrame>
					<PageTitle>{title}</PageTitle>
					<PageTitle>{'페이지 준비중 입니다.'}</PageTitle>
				</ContentFrame>
			</div>
		</ContainerFrame>
	);
};

export default Empty;
