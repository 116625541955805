import React from 'react';
import ceo from '../../asset/image/ceo/CEO_img.jpg'
import logo from '../../asset/image/clean_logo.png'
import label from '../../asset/image/sample/bolt.jpg'
import SideMenu from "../../component/sidemenu/SideMenu";
import {ceo_greeting, detailMenu} from "../../dataSet";
import {ContainerFrame, ContentFrame, PageTitle} from "../../style/Style-component";
import styled from "styled-components";

const Greeting = () => {
	return (
		<ContainerFrame labelPosition={'top'}>
			<div className={'top-label'} style={{backgroundImage:`url(${label})`}}/>
			<div style={{display:'flex',justifyContent:'center'}}>
				<SideMenu menuTitle={'회사 소개'} menuList={detailMenu.company}/>
				<ContentFrame>
					<PageTitle>{'CEO 인사말'}</PageTitle>
					<div style={{fontFamily:'S-CORE', fontSize:'22px', fontWeight:800, color:'#233763',marginTop:'8px'}}>{'재우산업'}</div>
					<div style={{display:'flex'}}>

						<div style={{fontFamily:'S-CORE',textAlign:'center', margin:'24px 24px 0 0',}}>
							<img src={logo} alt="logo" width={250}/>
							<div style={{whiteSpace:'pre-line',fontFamily:'S-CORE', fontSize:'27px', fontWeight:800, color:'#3b3d3f',margin:'8px 0'}}>{'성장하는 기업\n진취적이고 책임있는'}</div>
							<div style={{fontFamily:'S-CORE', fontSize:'30px', fontWeight:800, color:'#233763'}}>{'재우산업'}</div>
						</div>

						<div>
							<div style={{whiteSpace:'pre-line'}}>{ceo_greeting}</div>
							<div style={{display:'flex',justifyContent:'space-between'}}>
								<div style={{display:'flex',alignItems:'center'}}>
									<div style={{fontSize:'22px', marginRight:'12px'}}>{'대 표 이 사'}</div>
									<div style={{fontSize:'36px',fontWeight:700, fontFamily:'chosun'}}>{'송윤길'}</div>
								</div>
								<div style={{position:'relative'}}>
									<CEO_Image style={{backgroundImage:`url(${ceo})`}}/>
									<div style={{width:'238px', height:'272px', backgroundColor:'#233763',
										position:'absolute',top:'45px', left:'45px',zIndex:-1}}/>
								</div>
							</div>

						</div>
					</div>
				</ContentFrame>
			</div>
		</ContainerFrame>
	);
};

export default Greeting;

const CEO_Image = styled.div`
	width: 238px;
	height: 272px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	border: 15px solid #fff;
	position: relative;
	z-index: 1;
`