import React from 'react'
import styled from 'styled-components'

import Smart from '../../asset/image/SmartFactroy_Icon/smart-touch.jpg'
import Back from '../../asset/image/SmartFactroy_Icon/SMF.jpg'
import Step from '../../asset/image/SmartFactroy_Icon/SFi_imgDF.png'

import Decrease from '../../asset/image/SmartFactroy_Icon/Decrease.png'
import Conveyor from '../../asset/image/SmartFactroy_Icon/Conveyor.png'
import Prescription from '../../asset/image/SmartFactroy_Icon/Prescription.png'
import Compliance from '../../asset/image/SmartFactroy_Icon/Compliance.png'
import Competence from '../../asset/image/SmartFactroy_Icon/Competence.png'
import Graph_bar from '../../asset/image/SmartFactroy_Icon/Graph-bar.png'

import SmartView from '../../asset/image/SmartFactroy_Icon/Smart_Control1.jpg'
import SmartView2 from '../../asset/image/SmartFactroy_Icon/Smart_Control2.jpg'
import Part from '../../asset/image/SmartFactroy_Icon/part-sample.jpg'
import Safe from '../../asset/image/SmartFactroy_Icon/safe-sample.jpg'

import PMS from '../../asset/image/SmartFactroy_Icon/PMS_moniter.png'
import Ability from '../../asset/image/SmartFactroy_Icon/PMS_ability.png'
import Dash from '../../asset/image/SmartFactroy_Icon/PMS_Dashboard.png'
import Statics from '../../asset/image/SmartFactroy_Icon/PMS_statistics.png'
import Work from '../../asset/image/SmartFactroy_Icon/PMS_working.png'
import Analy from '../../asset/image/SmartFactroy_Icon/PMS_analysis.png'

import AOS from 'aos'


const SmartFactory = () => {

  AOS.init({
    offset: 400,
    delay: 300,
  })

  return (
    <div>

      <Main>
        <MainImg>
          <div style={{fontWeight:700,}}>Smart Factory</div>
          <div>스마트공장은 제품의 기획부터 판매까지 <br/>
            모든 생산과정을 ICT(정보통신)기술로 통합해 최소 비용과 시간으로 고객 맞춤형 제품을 생산하는 사람 중심의 첨단 지능형 공장.</div>
        </MainImg>
        <TextStyle>
          <div style={{fontWeight:700,}}>스마트팩토리 소개</div>
          <li>1. 스마트팩토리는 ICT 기술 덕분에 모든 설비나 장치가 무선통신으로 연결되어 있어,<br/>
            &nbsp;   전후 공정간 데이터를 자유롭게 연계할 수 있고 이를 통해 보다 유기적이고 통합적인 최적의 생산 환경을 이룩할 수 있을 것입니다.</li>
          <li>2. 대량 생산이 야기하는 재고의 불확실성 문제에서도 자유롭습니다.</li>
          <li>3. 자동화를 통해 생산 라인의 유연함이 더해져 개인 맞춤형 상품을 합리적인 가격에 즉각적으로 생산할 수 있습니다.</li>
        </TextStyle>
        <img src={Step} alt="" style={{width:'700px',
          padding:'30px',border:'1px solid gray',boxShadow:'0px 0px 3px 1px gray',marginTop:'50px',backgroundColor:'white',zIndex:1}}/>
        <Explain >공장의 기계 장비, 생산 라인 시스템<br/>
          Internet of Things (IoT) (사물 인터넷) 시스템<br/>
          인공 지능 (AI) 및 빅데이터 (BigData) 기술을 통해 정보를 수집하고 분석하며 사용자가 장비를 모니터링하고 제어하도록 지원합니다.</Explain>

        <div style={{margin:'120px',zIndex:1}}>
          <div style={{fontSize:'36px',margin:'70px 0',textAlign:'center',fontWeight:700,}}>스마트팩토리의 장점</div>
          <TextOutStyle
            // data-aos="zoom-in-down"
          >
            <img src={Decrease} alt="" />
            <div>1. 무인화가 가능해지며 인건비가 감소.</div>
          </TextOutStyle>

          <TextOutStyle2>
            <img src={Conveyor} alt=""/>
            <div>2. 공정 자동화로 생산효율성이 증대.</div>
          </TextOutStyle2>

          <TextOutStyle>
            <img src={Prescription} alt=""/>
            <div>3. 다품종 소량 생산이 가능하여 추가 시장 개발이 가능.</div>
          </TextOutStyle>

          <TextOutStyle2>
            <img src={Graph_bar} alt=""/>
            <div>4. 스마트그리드,IoT,공정관리 솔루션등을 통한 에너지 효율 증가.</div>
          </TextOutStyle2>

          <TextOutStyle>
            <img src={Competence} alt=""/>
            <div>5. 작업환경,장비등의 이상 및 변화를 감지하여 작업의 안정성을 증가.</div>
          </TextOutStyle>

          <TextOutStyle2>
            <img src={Compliance} alt=""/>
            <div>6. 공정을 실시간으로 진단 및 예방이 가능.</div>
          </TextOutStyle2>
        </div>



        <div style={{
          backgroundColor:'#ffffff',
          zIndex:1, width:'1920px',paddingBottom:'100px',textAlign:'center'}}>
          <TextStyle>
            {/* <div style={{display:'inline-block',color:'white',backgroundColor:'#233763',borderBottom:'3px solid white'}}>스마트팩토리 도입으로 인한 성과</div> */}
            <div style={{display:'inline-block', fontWeight:700,
              // borderBottom:'6px solid aliceblue',
              // boxShadow:'0px 10px 5px -5px aliceblue'
            }}>
              재우 산업은 스마트팩토리와 함께합니다.
            </div>
          </TextStyle>
          <Performance>
            <div style={{borderStartStartRadius:'30px', borderEndStartRadius:'30px'}}>
              <ul>
                <li
                  data-aos="fade-down"
                >더 스마트한 환경</li>
                <li data-aos="fade-right">원격으로 기계를 컨트롤 하고 정확도가 높은 자동화된 시스템으로<br/>
                  제품을 생산합니다.
                  <p/>
                  그로 인해 제품과 인명사고를 방지하여 좀 더 안전하고 편리한 <br/>환경에서 제품이 제작됩니다.</li>
              </ul>
            </div>
            <img src={Safe} alt=""  style={{borderStartEndRadius:'30px', borderEndEndRadius:'30px'}}/>
          </Performance>
          <Performance>
            <img src={SmartView2} alt="" style={{borderStartStartRadius:'30px', borderEndStartRadius:'30px'}}/>
            <div style={{borderStartEndRadius:'30px', borderEndEndRadius:'30px'}}>
              <ul>
                <li
                  data-aos="fade-down"
                >더 빠른 작업 문제 파악</li>
                <li data-aos="fade-left">스마트 네트워킹을 통한 실시간 확인으로 제조 공정이 투명해져<br/> 이를 통해 현재 작업 상황을 쉽게 파악하여 어떤 일이 발생했는지<br/>
                  빠르고 유연하게 문제를 개선하고 제어할 수 있는 <br/>시스템을 갖추고 있습니다.
                  <p/>이로써 불량율은 줄이고 제품의 질 또한 높아질 것 입니다.
                </li>
              </ul>
            </div>
          </Performance>
          <Performance>
            <div style={{borderStartStartRadius:'30px', borderEndStartRadius:'30px'}}>
              <ul>
                <li
                  data-aos="fade-down">
                  더 쉬운 최적의 프레스 상태 유지 </li>
                <li data-aos="fade-right">현재 가동중인 프레스의 전력 상태, 수명, 관리주기 등을<br/>
                  수치와 그래프로 바로 확인하여 기존에 파악하지 못했던 문제를 <br/>
                  문제가 발생하기 전에 미리 개선하여 실제 업무에 차질이 없도록 하고 있습니다.

                </li>
              </ul>
            </div>
            <img src={SmartView} alt=""  style={{borderStartEndRadius:'30px', borderEndEndRadius:'30px'}}/>
          </Performance>
          <Performance>
            <img src={Part} alt=""  style={{borderStartStartRadius:'30px', borderEndStartRadius:'30px'}}/>
            <div style={{borderStartEndRadius:'30px', borderEndEndRadius:'30px'}}>
              <ul>
                <li
                  data-aos="fade-down"
                >더 꼼꼼한 사후관리</li>
                <li data-aos="fade-left">스마트 팩토리 시스템을 통해 제품 생산 뿐 아니라, <br/>
                  생산 후에도 더 확실히 책임집니다. <p/>입,출고 데이터 기록을 통해 혹시나 발생한
                  문제에 대하여<br/> 빠르게 대처하여 피해가 없도록 하겠습니다.  </li>
              </ul>
            </div>
          </Performance>
        </div>

        <div style={{marginTop: '70px' ,zIndex:1}}>
          <div style={{textAlign:'center',margin:'70px',fontSize:'36px',fontWeight:700,}}>스마트팩토리를 적용한 재우산업</div>
          <PMSimg>
            <div>
              <img src={PMS} alt="" style={{height:'400px'}}/>
              <div>현재 가동중인 기계 상태를 바로 확인 가능합니다.</div>
            </div>
            <div>
              <img src={Ability} alt=""  style={{height:'400px'}}/>
              <div>기계가 작동하는데에 있어서 최적의 능력치를 계산하여 그래프로 볼 수 있습니다.</div>
            </div>
          </PMSimg>
          <PMSimg>
            <div>
              <img src={Dash} alt="" />
              <div>작동중인 기계 제어 상태를 보여줍니다.</div>
            </div>
            <div>
              <img src={Statics} alt=""/>
              <div>전체 가동시간 기준으로 각 시간별로 발생한 이슈가 로그를 통해 확인 됩니다. </div>
            </div>
          </PMSimg>
          <PMSimg>
            <div>
              <img src={Work} alt="" />
              <div>기계의 가동 횟수 등을 설정/관리 합니다.</div>
            </div>
            <div>
              <img src={Analy} alt="" />
              <div>매 시간마다 총 생산량과 최대 생산량의 지표를 한눈에 확인 가능합니다.</div>
            </div>
          </PMSimg>
        </div>
      </Main>
    </div>
  )
}

export default SmartFactory

const Main = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

&::after{
    content: "";

    background-size: 120%;
    background-image: url(${Back});
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 80px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
  }
`

const MainImg = styled.div`
  width: 1920px;
  height: 860px;
  display: flex;
  position: relative;
  flex-direction: column;
  color: aliceblue;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-shadow: 2px 2px 3px black;
  background-color: #233763;
  box-shadow: 0 0 5px 3px black;
  z-index: 1;
  
  &::before{
    content: "";
    position: absolute;
    /* background-color: #90aff1; */
    background-size: 100%;
    background-image: url(${Smart});
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -165px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.7;
  }
  
  
  &>div:nth-child(1){
    
    font-size: 60px;
    font-family: 'S-Core';
    position: relative;
    margin: 30px 0;
    transition: all 0.5s;
    &:hover{
      text-shadow: -2px -2px 2px black;
    }
    
  }
  &>div:nth-child(2){
    font-size: 18px;
    font-family: 'S-Core';
    white-space: pre-wrap;
    line-height: 2;
    position: relative;
    transition: all 0.5s;
    &:hover{
      text-shadow: -2px -2px 2px black;
    }
  }
`

const TextStyle = styled.div`
  

  margin-top: 100px;

  &>div:nth-child(1){

    font-size: 36px;
    font-family: 'S-Core';
    position: relative;
    margin: 30px 0;
    text-align: center;

  }
    &>li{
    font-size: 18px;
      font-family: 'S-Core';
    white-space: pre-wrap;
    line-height: 2;
    position: relative;
    text-align: left;
  }
`

const Explain = styled.li`
    font-size: 18px;
    font-family: 'S-Core';
    white-space: pre-wrap;
    line-height: 2;
    position: relative;
    text-align: center;
    margin-top: 50px;
`

const TextOutStyle = styled.div`
  display: flex;
  margin: 10px 0;
  /* border: 1px solid #233763; */
  border-radius: 30px;
  width: 800px;
  height: 80px;
  background-color: aliceblue;
  box-shadow: 3px 3px 5px lightblue;
  z-index: 1;
  align-items: center;
  color: #233763;

  transition: all 0.2s;
  &:hover{
    
    transform: scale(105%);

  }
  
  &>img{
  
  width: 55px;
  margin: 0 30px;
  
  }

  &>div{
    font-size: 18px;
    font-family: 'S-Core';
    font-weight: 700;
  }
`

const TextOutStyle2 = styled.div`
  display: flex;
  margin: 10px 0;
  /* border: 1px solid #233763; */
  border-radius: 30px;
  width: 800px;
  height: 80px;
  background-color: #d8f5f2;
  box-shadow: 3px 3px 5px lightblue;
  z-index: 1;
  align-items: center;
  color: #233763;
  transition: all 0.5s;
  &:hover{
    
    transform: scale(105%);

  }
  &>img{
  width: 55px;
  margin: 0 30px;
  }


  &>div{
    font-size: 18px;
    font-family: 'S-Core';
    font-weight: 700;
  }
`

const Performance = styled.div`
z-index: 1;
display: flex;
justify-content: center;
font-family: 'S-Core';
margin: 50px 0;
align-self: center;
align-items: center;
border-radius: 30px;


&>img{
  border: 1px solid lightgray;
  width: 400px;
  height: 320px;
  background-color: whitesmoke;
  padding: 20px;
  
}
&>div{

  padding: 20px;
  width: 600px;
  height: 320px;
  border: 1px solid lightgray;
  color: gray;
  background-color: whitesmoke;
  /* box-shadow: 0 0 3px black; */


  &>ul{
    list-style-type: none;
    padding: 15px;
    text-align: left;

    &>li:nth-child(1){
      margin: 30px;
      padding-bottom: 8px;
      font-size: 24px;
      border-bottom: 2px solid #2a58bd;
      display: inline;

    }
    &>li:nth-child(2){
      margin: 30px;
      z-index: 2;
      font-size: 18px;
      line-height: 1.8;
    }
  }

}
`
const PMSimg = styled.div`

width: 1700px;
display: flex;
align-self: center;
align-items: center;
justify-content: space-around;


&>div>img{
  border: 1px solid lightgray;
  width: 800px;
  height: 550px;
  padding:10px;
  background-color: white;
}

&>div>div{
  width: 800px;
  height: 100px;
  text-align: center;
  font-family: 'Pretendard-SemiBold';
}
`