import React from 'react';
import styled from "styled-components";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {useLocation} from "react-router-dom";

interface Page_Props {
	container: any;

}
const Page:React.FunctionComponent<Page_Props> = ({container}) => {
	const location = useLocation()
	return (
		<PageComponent>
			<Header/>
			{container}
			{!location.pathname.includes('main') && <Footer/>}
		</PageComponent>
	);
};

export default Page;

const PageComponent = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
`