import React from 'react';
import {ContainerFrame, ContentFrame, PageTitle} from "../../style/Style-component";
import label from "../../asset/image/sample/bolt.jpg";
import SideMenu from "../../component/sidemenu/SideMenu";
import {detailMenu} from "../../dataSet";
import styled from "styled-components";

const Location = () => {

	const locationInfo = [
		{category:'상호', value:'재우산업'},
		{category:'주소', value:'경기 김포시 대곶면 율마로 181번길 31-34'},
		{category:'연락처', value:'TEL:(031)987-2718'},
		{category:'팩스', value:'FAX:(031)987-2719'},
	]


	React.useEffect(() => {

		const kakao = (window as any).kakao;

		const container = document.getElementById('map');
		const options = {
			center: new kakao.maps.LatLng(37.66625526106803, 126.59430384628553),
			level: 3,
		};
		const map = new kakao.maps.Map(container, options)
		const markerPosition = new kakao.maps.LatLng(37.66614409433117, 126.59407210334999)
		const marker = new kakao.maps.Marker({
			position: markerPosition


		})
		map.addOverlayMapTypeId(kakao.maps.MapTypeId.TERRAIN);
		marker.setMap(map)

		const center = map.getCenter();
		console.log(center)


		var iwContent =
				'<div style="padding:5px;color:#223763;margin:5px 0 5px 40px;user-select:none"><a href="https://map.kakao.com/link/to/경기 김포시 대곶면 율마로 181번길 31-34,37.66625526106803, 126.59430384628553"  style="color:#223763;text-decoration:none;"target="_blank">재우산업</a></div>',
			iwPosition = new kakao.maps.LatLng(37.66625526106803, 126.59430384628553)

		const infowindow = new kakao.maps.InfoWindow({
			position : iwPosition,
			content : iwContent
		});

		infowindow.open(map, marker)

	}, [])

	return (
		<ContainerFrame labelPosition={'top'}>
			<div className={'top-label'} style={{backgroundImage:`url(${label})`}}/>
			<div style={{display:'flex',justifyContent:'center'}}>
				<SideMenu menuTitle={'회사 소개'} menuList={detailMenu.company}/>
				<ContentFrame>
					<PageTitle>{'찾아오시는 길'}</PageTitle>
					<div id="map" style={{width:"100%", height:"490px",margin:'30px 0',border:'1px solid #233763'}}/>
					<div style={{
						borderLeft:'1px solid #223763',
						borderRight:'1px solid #223763',
						borderTop:'2px solid #223763',
						borderBottom:'2px solid #223763'
					}}>
						{locationInfo.map((v)=>(
							<LocationInfo>
								<div>{v.category}</div>
								<div>{v.value}</div>
							</LocationInfo>
						))}
					</div>
				</ContentFrame>
			</div>
		</ContainerFrame>
	);
};

export default Location;

const LocationInfo = styled.div`
	display: grid;
	grid-template-columns: 200px 1fr;
	height: 50px;
	align-content: center;
	
	&>div{
    box-sizing: border-box;
    padding-left: 24px;
		line-height: 50px;
		border: 1px solid #fff;
	}
	&>div:first-child{
		background-color: #d0e3f6;
	}
	&>div:last-child{
		
	}
`