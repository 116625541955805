import React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import Page from "../page/Page";
import Greeting from "../container/company/Greeting";
import Location from "../container/company/Location";
import Empty from "../container/Empty";
import {detailMenu} from "../dataSet";
import MainPage from "../page/MainPage";
import Facility from "../container/facility/Facility";
import MainTopContainer from "../container/main/MainTopContainer";
import FirstFactory from "../container/facility/FirstFactory";
import SecondFactory from "../container/facility/SecondFactory";
import MoldRoom from "../container/facility/MoldRoom";
import History from "../container/company/History";
import SmartFactory from "../container/smart_factory/SmartFactory";

const Router = () => {
	return (
		<Routes>
			{/*<Route element={<MainPage/>} path={'/main'}/>*/}
			<Route element={<Page container={<MainTopContainer/>}/>} path={'/Main'}/>

			<Route element={<Page container={<Greeting/>}/>} path={'/greeting'}/>
			<Route element={<Page container={<Empty title={'협력업체'} sideTitle={'회사 소개'} sideDetailMenu={detailMenu.company}/>}/>} path={'/partner'}/>
			<Route element={<Page container={<History/>}/>} path={'/history'}/>
			<Route element={<Page container={<Empty title={'인증현황'} sideTitle={'회사 소개'} sideDetailMenu={detailMenu.company}/>}/>} path={'/certificate'}/>
			<Route element={<Page container={<Location/>}/>} path={'/location'}/>

			<Route element={<Page container={<Empty title={'금형설계 및 제작'} sideTitle={'제품 소개'} sideDetailMenu={detailMenu.product}/>}/>} path={'/mold'}/>
			<Route element={<Page container={<Empty title={'프레스 가공'} sideTitle={'제품 소개'} sideDetailMenu={detailMenu.product}/>}/>} path={'/press'}/>
			<Route element={<Page container={<Empty title={'Locking-Nut'} sideTitle={'제품 소개'} sideDetailMenu={detailMenu.product}/>}/>} path={'/locking'}/>

			<Route element={<Page container={<FirstFactory/>}/>} path={'/factory1'}/>
			<Route element={<Page container={<SecondFactory/>}/>} path={'/factory2'}/>
			<Route element={<Page container={<MoldRoom/>}/>} path={'/moldroom'}/>

			<Route element={<Page container={<SmartFactory/>}/>} path={'/smart'}/>
			{/*<Route element={<Page container={<Empty title={'Smart Factory'} sideTitle={'Smart Factory'} sideDetailMenu={detailMenu.smart}/>}/>} path={'/smart'}/>*/}

			<Route element={<Page container={<Empty title={'온라인 상담'} sideTitle={'고객지원'} sideDetailMenu={detailMenu.customer}/>}/>} path={'/consulting'}/>
			<Route element={<Page container={<Empty title={'공지사항'} sideTitle={'고객지원'} sideDetailMenu={detailMenu.customer}/>}/>} path={'/notice'}/>

			<Route path={"/*"} element={<Navigate replace to={'/main'}/>}/>
			<Route path={"/"} element={<Navigate replace to={'/main'}/>}/>
		</Routes>
	);
};

export default Router;
