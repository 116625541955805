import React from 'react';
import styled from "styled-components";

interface Box_Props{
  imgUrl?: string
  facilityName?: string
  width?: number
  selectEvent?:()=>void
}
const FacilityInfoBox:React.FunctionComponent<Box_Props> = ({imgUrl, facilityName,width, selectEvent}) => {

  return (
    <React.Fragment>
      <BaseComponent onClick={selectEvent}>
        <img src={imgUrl} style={{maxWidth:'400px', maxHeight:'400px', borderRadius:'6px', boxShadow:'0 3px 6px gray',display:'block', cursor:'pointer'}} alt=""/>
        <div style={{display:'flex',margin:'6px 0 8px'}}>
          <div style={{backgroundColor:'#d2d2d2',height:'36px', width:'70px',boxSizing:'border-box',display:'flex',justifyContent:'center',lineHeight:'36px'}}>{'설비명'}</div>
          <div style={{height:'36px', boxSizing:'border-box', padding:'8px', borderTop:'2px solid #d2d2d2', borderBottom:'2px solid #d2d2d2', borderRight:'2px solid #d2d2d2', minWidth:`${!width?70 : width-70}px`}}>{!facilityName? '-' : facilityName}</div>
        </div>
      </BaseComponent>
    </React.Fragment>

  );
};

export default FacilityInfoBox;

const BaseComponent = styled.div`
`